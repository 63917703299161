import axios, { AxiosInstance } from 'axios';



const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;



interface OpenAIResponse {
  choices: Array<{
    message: {
      content: string;
    };
  }>;
}



interface DallEResponse {
  data: Array<{
    url: string;
  }>;
}



class OpenAIService {
  private client: AxiosInstance;



  constructor() {
    this.client = axios.create({
      baseURL: 'https://api.openai.com/v1',
      headers: {
        'Authorization': `Bearer ${OPENAI_API_KEY}`,
        'Content-Type': 'application/json'
      }
    });
  }



  async askQuestion(question: string): Promise<string> {
    try {
      const response = await this.client.post<OpenAIResponse>('/chat/completions', {
        model: "gpt-3.5-turbo-0125",
        messages: [{ role: "user", content: question }],
        max_tokens: 100,
        temperature: 0.7
      });
      
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error('Error asking question to OpenAI:', error);
      throw new Error('Failed to get an answer from AI');
    }
  }



  async generateImage(prompt: string): Promise<string> {
    try {
      const refinedPrompt = await this.refinePrompt(prompt);
      console.log(refinedPrompt);
      /**
       * dall-e3
       * 1024x1024 = $0.08 / img
       * dall-e 2
       * 1024x1024 = $0.02 / img
       * 512x512 = $0.018 / img
       * 256x256 = $0.016 / img
       */
      const response = await this.client.post<DallEResponse>('/images/generations', {
        model: "dall-e-2",
        prompt: refinedPrompt,
        n: 1,
        size: "256x256"
      });

      return response.data.data[0].url;
      //return 'https://placehold.co/500x500';
    } catch (error) {
      console.error('Error generating image with DALL-E:', error);
      throw new Error('Failed to generate image from AI');
    }
  }



  async refinePrompt(prompt: string): Promise<string> {
    /*
    const refinedPrompt = await this.askQuestion(
      `Refine the following prompt for better image generation:\n\n"${prompt}"`
    );
    console.log(refinedPrompt);
    */
    const test = "Imagine a fluffy orange tabby cat lounging lazily in a sunny window, its eyes half closed in contentment as it basks in the warmth.";
    return test;
  };
}



export const openAIService = new OpenAIService();