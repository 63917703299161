import { createCanvas } from 'canvas';
import { createNoise2D, createNoise3D, createNoise4D } from 'simplex-noise';

class AdvancedRandom {
    private state: number;

    constructor(seed: number) {
        this.state = seed;
    }

    next(): number {
        this.state = (this.state * 1664525 + 1013904223) >>> 0;
        return this.state / 4294967296;
    }

    range(min: number, max: number): number {
        return min + this.next() * (max - min);
    }

    pick<T>(array: T[]): T {
        return array[Math.floor(this.next() * array.length)];
    }

    gaussian(mean: number = 0, std: number = 1): number {
        let u = 0, v = 0;
        while (u === 0) u = this.next();
        while (v === 0) v = this.next();
        const num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
        return num * std + mean;
    }
}

class Color {
    constructor(public r: number, public g: number, public b: number, public a: number = 1) {}

    static fromHSL(h: number, s: number, l: number, a: number = 1): Color {
        const hue2rgb = (p: number, q: number, t: number) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1/6) return p + (q - p) * 6 * t;
            if (t < 1/2) return q;
            if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        const r = hue2rgb(p, q, h + 1/3);
        const g = hue2rgb(p, q, h);
        const b = hue2rgb(p, q, h - 1/3);

        return new Color(r * 255, g * 255, b * 255, a);
    }

    mix(other: Color, t: number): Color {
        return new Color(
            this.r * (1 - t) + other.r * t,
            this.g * (1 - t) + other.g * t,
            this.b * (1 - t) + other.b * t,
            this.a * (1 - t) + other.a * t
        );
    }

    toString(): string {
        return `rgba(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(this.b)}, ${this.a})`;
    }
}

class NoiseGenerator {
    private noise2D: (x: number, y: number) => number;
    private noise3D: (x: number, y: number, z: number) => number;
    private noise4D: (x: number, y: number, z: number, w: number) => number;

    constructor(random: AdvancedRandom) {
        this.noise2D = createNoise2D(() => random.next());
        this.noise3D = createNoise3D(() => random.next());
        this.noise4D = createNoise4D(() => random.next());
    }

    fractalNoise2D(x: number, y: number, octaves: number, lacunarity: number, persistence: number): number {
        let value = 0;
        let amplitude = 1;
        let frequency = 1;
        let maxValue = 0;

        for (let i = 0; i < octaves; i++) {
            value += this.noise2D(x * frequency, y * frequency) * amplitude;
            maxValue += amplitude;
            amplitude *= persistence;
            frequency *= lacunarity;
        }

        return value / maxValue;
    }

    // Similar methods for fractalNoise3D and fractalNoise4D...
}

interface Layer {
    generate: (x: number, y: number, t: number) => number;
    blendMode: (base: number, layer: number) => number;
    weight: number;
}

class ImageGenerator {
    private random: AdvancedRandom;
    private noise: NoiseGenerator;
    private layers: Layer[];
    private colorPalette: Color[];

    constructor(seed: string) {
        this.random = new AdvancedRandom(this.hashCode(seed));
        this.noise = new NoiseGenerator(this.random);
        this.layers = this.generateLayers();
        this.colorPalette = this.generateColorPalette();
    }

    private hashCode(str: string): number {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return hash >>> 0;
    }

    private generateLayers(): Layer[] {
        const numLayers = this.random.range(3, 7);
        return Array.from({ length: numLayers }, () => ({
            generate: this.generateLayerFunction(),
            blendMode: this.random.pick([
                (base, layer) => base + layer,
                (base, layer) => base * layer,
                (base, layer) => Math.max(base, layer),
                (base, layer) => Math.min(base, layer),
            ]),
            weight: this.random.next(),
        }));
    }

    private generateLayerFunction(): (x: number, y: number, t: number) => number {
      const type = this.random.pick(['noise', 'pattern', 'geometric']);
      switch (type) {
          case 'noise':
              return (x, y, t) => this.noise.fractalNoise2D(
                  x * this.random.range(1, 5),
                  y * this.random.range(1, 5),
                  this.random.range(3, 8),
                  this.random.range(1.5, 2.5),
                  this.random.range(0.3, 0.7)
              );
          case 'pattern':
              return (x, y, t) => Math.sin(x * this.random.range(1, 10) + t) * Math.cos(y * this.random.range(1, 10) + t);
          case 'geometric':
              return (x, y, t) => {
                  const dx = x - 0.5;
                  const dy = y - 0.5;
                  return Math.sqrt(dx * dx + dy * dy) * 2 + Math.sin(t);
              };
          default:
              // Add a default case to ensure a function is always returned
              return (x, y, t) => Math.sin(x + y + t);
      }
    }

    private generateColorPalette(): Color[] {
        const numColors = this.random.range(3, 6);
        return Array.from({ length: numColors }, () => 
            Color.fromHSL(this.random.next(), this.random.range(0.5, 1), this.random.range(0.3, 0.7))
        );
    }

    generate(width: number, height: number): string {
        const canvas = createCanvas(width, height);
        const ctx = canvas.getContext('2d');
        const imageData = ctx.createImageData(width, height);

        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                const nx = x / width;
                const ny = y / height;
                const t = Date.now() / 1000;

                let value = 0;
                let totalWeight = 0;
                for (const layer of this.layers) {
                    const layerValue = layer.generate(nx, ny, t);
                    value = layer.blendMode(value, layerValue * layer.weight);
                    totalWeight += layer.weight;
                }
                value /= totalWeight;

                const colorIndex = Math.floor((value + 1) / 2 * this.colorPalette.length);
                const color1 = this.colorPalette[colorIndex % this.colorPalette.length];
                const color2 = this.colorPalette[(colorIndex + 1) % this.colorPalette.length];
                const t2 = (value + 1) / 2 * this.colorPalette.length - colorIndex;
                const finalColor = color1.mix(color2, t2);

                const index = (y * width + x) * 4;
                imageData.data[index] = finalColor.r;
                imageData.data[index + 1] = finalColor.g;
                imageData.data[index + 2] = finalColor.b;
                imageData.data[index + 3] = finalColor.a * 255;
            }
        }

        ctx.putImageData(imageData, 0, 0);
        return canvas.toDataURL();
    }
}

export const generateImage = (prompt: string, width: number = 500, height: number = 500): string => {
    const generator = new ImageGenerator(prompt);
    return generator.generate(width, height);
};