import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'development' ? 
'http://localhost:3001/api/stripe' : 'https://algomigo-be.vercel.app/api/stripe';

export const createCheckoutSession = async (userEmail: string): Promise<string> => {
  const response = await axios.post(`${API_URL}/create-checkout-session`, { userEmail });
  return response.data.sessionId;
};

export const redirectToCheckout = async (sessionId: string): Promise<void> => {
  const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
  if (!stripe) {
    throw new Error('Failed to load Stripe');
  }
  const { error } = await stripe.redirectToCheckout({ sessionId });
  if (error) {
    throw new Error(error.message);
  }
};