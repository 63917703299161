import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);



const StripeWrapper: React.FC<{ email: string, handleEmailChange: (targetValue: string) => void }> = ({ 
  email, handleEmailChange }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm email={email} handleEmailChange={handleEmailChange} />
    </Elements>
  );
};



export default StripeWrapper;