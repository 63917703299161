import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';



interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  sessionId: string | null;
  onPaymentConfirmed: (email: string) => void;
}



const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? 
  'http://localhost:3001/api' : 'https://algomigo-be.vercel.app/api';



export const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose, sessionId, onPaymentConfirmed }) => {
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');

  const bgColor = useColorModeValue('white', '#2C7A7B');
  const textColor = useColorModeValue('#2C7A7B', 'white');
  const accentColor = '#38B2AC';



  useEffect(() => {
    async function checkPaymentStatus() {
      try {
        const response = await fetch(`${API_URL}/stripe/check-payment-status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sessionId }),
        });
        const data = await response.json();
        console.log('Payment status:', data);
        if (data.success) {
          setStatus('success');
          onPaymentConfirmed(data.email);
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
        setStatus('error');
      }
    }

    if (isOpen && sessionId) {
      checkPaymentStatus();
    }
  }, [isOpen, sessionId, onPaymentConfirmed]);



  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg={bgColor}>
        <ModalHeader color={textColor}>Betalingsstatus</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {status === 'loading' && (
              <Text color={textColor}>Verifiserer din betaling...</Text>
            )}
            {status === 'success' && (
              <>
                <Text color={textColor} fontWeight="bold">Betaling vellykket!</Text>
                <Text color={textColor}>
                  Takk for ditt kjøp. Din konto har blitt oppdatert med nye kreditter.
                </Text>
              </>
            )}
            {status === 'error' && (
              <Text color={textColor}>
                Det oppstod en feil ved behandling av betalingen din. Vennligst kontakt support.
              </Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button 
            bg={accentColor} 
            color="white" 
            onClick={onClose}
            _hover={{ bg: '#4FD1C5' }}
          >
            Lukk
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};