import React, { createContext, useState, useContext, useEffect } from 'react';



interface VisitorContextType {
  credits: number;
  updateCredits: (newCredits: number) => void;
}



const VisitorContext = createContext<VisitorContextType | undefined>(undefined);



export const VisitorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [credits, setCredits] = useState<number>(() => {
    const storedCredits = localStorage.getItem('visitorCredits');
    return storedCredits ? parseInt(storedCredits, 10) : 5;
  });



  useEffect(() => {
    localStorage.setItem('visitorCredits', credits.toString());
  }, [credits]);


  
  const updateCredits = (newCredits: number) => {
    setCredits(newCredits);
  };



  return (
    <VisitorContext.Provider value={{ credits, updateCredits }}>
      {children}
    </VisitorContext.Provider>
  );
};



export const useVisitor = () => {
  const context = useContext(VisitorContext);
  if (context === undefined) {
    throw new Error('useVisitor must be used within a VisitorProvider');
  }
  return context;
};