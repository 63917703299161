import React, { useState } from 'react';
import { Button, Text, VStack } from '@chakra-ui/react';
import { createCheckoutSession, redirectToCheckout } from './stripeApi';



const API_URL = process.env.REACT_APP_NODE_ENV === 'production' ? 
  'https://algomigo-be.vercel.app' : 'http://localhost:3001';



const allowDbTest = process.env.REACT_APP_ALLOW_DB_TEST === 'true';



const CheckoutForm: React.FC<{ email: string, handleEmailChange: (targetValue: string) => void }> = ({ 
  email, handleEmailChange }) => {
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState<string | null>(null);



  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setProcessing(true);

    try {
      const sessionId = await createCheckoutSession(email);
      handleEmailChange(email);
      await redirectToCheckout(sessionId);
    } catch (err) {
      setProcessing(false);
    }
  };



  const handleTest = async () => {
    console.log('handleTest function called');
    try {
      const response = await fetch(`${API_URL}/api/admin/get-users`);
      console.log('API call to /api/admin/get-users made');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const dataToDisplay = data && Object.keys(data).length > 0 ? data : [];
      setResult(JSON.stringify(dataToDisplay, null, 2));
    } catch (error) {
      console.error('Error occurred:', error);
      setResult(`Error: ${(error as Error).message}`);
    }
  };



  const handleAddUserTest = async () => {
    console.log('handleAddUserTest function called');
    try {
      const response = await fetch(`${API_URL}/api/admin/add-user-test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: 'test222@test222.com' }),
      });
      console.log('API call to /api/admin/add-user-test made');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('User added successfully:', data);
      setResult(`User added successfully: ${JSON.stringify(data, null, 2)}`);
    } catch (error) {
      console.error('Error adding user:', error);
      setResult(`Error adding user: ${(error as Error).message}`);
    }
  };



  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4}>
        {(process.env.REACT_APP_NODE_ENV === 'development' || allowDbTest) && (
          <>
            <Button onClick={handleTest}>
              Få tilgang til databasen
            </Button>
            <Button onClick={handleAddUserTest}>
              Legg til bruker-test
            </Button>
          </>
        )}
        <Text whiteSpace="pre-wrap">
          {result}
        </Text>
        <Button 
          type="submit" 
          isLoading={processing} 
          loadingText="laster inn siden"
          bg={'#38B2AC'} 
          color="white" 
          _hover={{ bg: '#4FD1C5' }}
        >
          betal her
        </Button>
      </VStack>
    </form>
  );
};



export default CheckoutForm;