import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Container,
  useColorModeValue,
  Input,
  useToast,
  Spinner,
  VStack,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useMediaQuery,
  Link,
} from '@chakra-ui/react';
import { FaHistory, FaTrash } from 'react-icons/fa';
import { openAIService } from './openaiService';
import AlgoBlobIconSrc from './AlgoBlobIcon.svg';
import StripeWrapper from './StripeWrapper';
import { SuccessModal } from './SuccessModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';



const enablePaymentFunctionality = process.env.REACT_APP_ENABLE_PAYMENT_FUNCTIONALITY === 'true';
const enableNftFunctionality = process.env.REACT_APP_ENABLE_NFT_FUNCTIONALITY === 'true';



interface LogEntry {
  question: string;
  answer: string;
}



function MainPage() {
  const { user, updateUser, updateUserCredits } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  
  let visitorCredits = localStorage.getItem('visitorCredits') ? parseInt(localStorage.getItem('visitorCredits')!) : 0;

  const [userEmail, setUserEmail] = useState<string>('');
  const [inputEmail, setInputEmail] = useState<string>('');

  const [inputQuestion, setInputQuestion] = useState<string>('');
  const [currentQuestion, setCurrentQuestion] = useState<string>('');
  const [answer, setAnswer] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string | null>(null);

  const [log, setLog] = useState<LogEntry[]>(() => {
    const storedLog = localStorage.getItem('questionLog');
    return storedLog ? JSON.parse(storedLog) : [];
  });

  const [selectedLogEntry, setSelectedLogEntry] = useState<LogEntry | null>(null);
  const { isOpen: isLogOpen, onOpen: onLogOpen, onClose: onLogClose } = useDisclosure();
  const { isOpen: isSubOpen, onOpen: onSubOpen, onClose: onSubClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 800px)");

  const toast = useToast();

  const bgColor = useColorModeValue('#E6F7F5', '#1D4A4D');
  const boxBgColor = useColorModeValue('white', '#2C7A7B');
  const textColor = useColorModeValue('#2C7A7B', 'white');
  const accentColor = '#38B2AC';
  const hoverBgColor = useColorModeValue('#F0FFFD', '#38B2AC');



  const handleSuccessRedirect = useCallback((sessionId: string) => {
    setSessionId(sessionId);
    setIsSuccessModalOpen(true);
  }, []);



  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const sessionIdParam = urlParams.get('session_id');
    if (sessionIdParam) {
      handleSuccessRedirect(sessionIdParam);
      navigate('/', { replace: true });
    }
  }, [location, handleSuccessRedirect, navigate]);



  useEffect(() => {
    const setup = async () => {
      const storedEmail = localStorage.getItem('userEmail');
      if (storedEmail) {
        setUserEmail(storedEmail);
      }
    };
    
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      setup();
    }

    // Load log from localStorage
    const storedLog = localStorage.getItem('questionLog');
    if (storedLog) {
      setLog(JSON.parse(storedLog));
    }
  }, [toast, userEmail, setLog]);



  useEffect(() => {
    // Save log to localStorage whenever it changes
    localStorage.setItem('questionLog', JSON.stringify(log));
  }, [log]);



  const handleQuestionSubmit = async () => {
    if ((!user || (enablePaymentFunctionality && user && user.credits <= 0)) && (!user && visitorCredits <= 0)) {
      onSubOpen();
      return;
    }

    if (!inputQuestion.trim()) {
      toast({
        title: 'tomt spørsmål',
        description: 'vennligst skriv inn et spørsmål før du sender.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    } 

    if ((user && !user.hasPaid && enablePaymentFunctionality) 
      || (user && enablePaymentFunctionality && user.credits <= 0)) {
      onSubOpen();
      return;
    }

    if ((!user && visitorCredits <= 0 && enablePaymentFunctionality) || (user && user.credits <= 0 && enablePaymentFunctionality)) {
      toast({
        title: 'ikke nok kreditter :/',
        description: 'du har ikke nok kreditter',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    setAnswer('');
    setCurrentQuestion(inputQuestion);
    try {
      const aiAnswer = await openAIService.askQuestion(inputQuestion);
      if (!user) {
        visitorCredits -= 1;
        localStorage.setItem('visitorCredits', visitorCredits.toString());
      }
      else {
        localStorage.setItem('visitorCredits', '0');
      }

      setAnswer(aiAnswer);
      setLog(prevLog => [{ question: inputQuestion, answer: aiAnswer }, ...prevLog]);
      setInputQuestion('');

      if (enablePaymentFunctionality && user) {
        const newCredits = user.credits - 1;
        await updateUserCredits(newCredits);
      }

    } catch (error) {
      console.error('Could not get answer:', error);
      toast({
        title: 'Feil',
        description: 'Kunne ikke få svar. Vennligst prøv igjen.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };



  const openLogEntryModal = useCallback((entry: LogEntry) => {
    setSelectedLogEntry(entry);
    onLogOpen();
  }, [onLogOpen]);



  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleQuestionSubmit();

      if ((!user && visitorCredits <= 0 && enablePaymentFunctionality) || (user && user.credits <= 0 && enablePaymentFunctionality)) {
        toast({
          title: 'ikke nok kreditter :/',
          description: 'du har ikke nok kreditter',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!inputQuestion.trim()) {
        toast({
          title: 'tomt spørsmål',
          description: 'vennligst skriv inn et spørsmål før du sender.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
        return;
      } 
    }
  };



  const handleClearLog = () => {
    if (log.length > 0) {
      setLog([]);
      localStorage.removeItem('questionLog');
      toast({
        title: 'slettet',
        description: 'loggen er renset.',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'ingen logg',
        description: 'det er ingen spørsmål stilt enda.',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    }
  };



  const handlePaymentConfirmed = useCallback((email: string) => {
    updateUser(email);
  }, [updateUser]);



  const handleEmailChange = (targetValue: string) => {
    setInputEmail(targetValue);
  };


  
  return (
    <Box bg={bgColor} minH="100vh" py={10} fontFamily="'inter', sans-serif">
      {enableNftFunctionality && (
        <Button
          position="absolute"
          top={4}
          left={4}
          bg={boxBgColor}
          color={textColor}
          _hover={{ bg: hoverBgColor }}
          onClick={() => window.location.href = '/nft'}
          borderWidth={1}
          borderColor={accentColor}
        >
          go to NFT
        </Button>
      )}
      {enablePaymentFunctionality && (
        <Box
          position="absolute"
          top={4}
          right={4}
          bg={boxBgColor}
          p={3}
          rounded="md"
          borderWidth={1}
          borderColor={accentColor}
        >
          <Text color={textColor} fontWeight="bold">
            kreditter: {user ? user.credits : localStorage.getItem('visitorCredits') ? localStorage.getItem('visitorCredits') : 0}
          </Text>
        </Box>
      )}

      <Container maxW="container.lg">
        <Flex direction="column">
          <VStack spacing={8} align="stretch" mb={8}>
            <Flex direction="column" align="center" justify="center">
              <Link href="/" _hover={{ textDecoration: 'none' }}>
                <Heading
                  as="h1"
                  fontSize={['3xl', '4xl', '5xl']}
                  fontWeight="extrabold"
                  color={accentColor}
                  textAlign="center"
                  letterSpacing="tight"
                  mb={4}
                  cursor="pointer"
                >
                  algomigo
                </Heading>
              </Link>
              <Text fontSize="lg" color={textColor} textAlign="center" mb={6}>
                din AI-følgesvenn for gode valg
              </Text>
            </Flex>
            
            <Box position="relative">
              {log.length > 0 && currentQuestion !== '' && (
                <Box
                  position="absolute"
                  top="-60px"
                  left="15%"
                  transform="translateX(50%)"
                  width="120px"
                  height="60px"
                  overflow="hidden"
                  zIndex={1}
                >
                  <img
                    src={AlgoBlobIconSrc}
                    alt="algo blob ikon"
                    width="120"
                    height="120"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center top"
                    }}
                  />
                </Box>
              )}
              <Flex mb={6}>
                <Input
                  placeholder="skriv ditt spørsmål her"
                  value={inputQuestion}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputQuestion(e.target.value)}
                  onKeyDown={handleKeyPress}
                  size="lg"
                  mr={4}
                  borderColor={accentColor}
                  borderWidth="2px"
                  color={textColor}
                  fontWeight="bold"
                  _placeholder={{ color: '#2C7A7B' }}
                  _hover={{ borderColor: '#4FD1C5', borderWidth: "2px" }}
                  _focus={{ borderColor: '#4FD1C5', boxShadow: `0 0 0 2px #4FD1C5`, borderWidth: "2px" }}
                />
                <Button
                  bg={accentColor}
                  color="white"
                  onClick={handleQuestionSubmit}
                  isLoading={isLoading}
                  loadingText="tenker..."
                  spinner={<></>}
                  fontSize="md"
                  size="lg"
                  px={8}
                  _hover={{ bg: '#4FD1C5' }}
                >
                  {isLoading 
                    ? 'tenker...' 
                    : visitorCredits > 0 || (user && user.credits > 0)
                      ? 'spør' 
                      : 'betal'
                  }
                </Button>
              </Flex>
            </Box>

            <Box
              bg={boxBgColor}
              p={8}
              rounded="xl"
              boxShadow="md"
              borderWidth={1}
              borderColor={accentColor}
              minHeight="300px"
              position="relative"
              overflow="hidden"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {isLoading ? (
                <Spinner size="xl" color={accentColor} thickness="4px" speed="0.65s" />
              ) : currentQuestion ? (
                <VStack align="start" spacing={6} width="100%">
                  <Text color={textColor} fontSize="md">
                    {currentQuestion}
                  </Text>
                  <Text color={textColor} fontSize="lg" whiteSpace="pre-wrap" fontWeight="bold">
                    {answer}
                  </Text>
                </VStack>
              ) : (
                <VStack spacing={6}>
                  <img src={AlgoBlobIconSrc} alt="algo blob ikon" width="120px" height="120px" />
                  <Text color={textColor} fontSize="xl">still meg et spørsmål!</Text>
                </VStack>
              )}
            </Box>
          </VStack>
          
          <VStack 
            align="stretch" 
            bg={boxBgColor} 
            p={6} 
            rounded="xl" 
            boxShadow="md" 
            maxHeight={isMobile ? "auto" : "70vh"} 
            overflowY="auto"
          >
            <Flex align="center" justify="space-between" mb={4}>
              <Flex align="center">
                <FaHistory size={24} color={accentColor} />
                <Text ml={3} fontSize="xl" fontWeight="bold" color={textColor}>spørsmålslogg</Text>
              </Flex>
              <Button
                size="sm"
                bg={accentColor}
                color="white"
                _hover={{ bg: '#4FD1C5' }}
                onClick={handleClearLog}
              >
                <FaTrash size={16} />
              </Button>
            </Flex>
            <Divider mb={4} />
            {log.length > 0 ? (
              log.map((entry, index) => (
                <Box 
                  key={index} 
                  mb={4} 
                  p={4}
                  cursor="pointer" 
                  onClick={() => openLogEntryModal(entry)}
                  borderWidth={1}
                  borderColor={accentColor}
                  borderRadius="md"
                  _hover={{ bg: hoverBgColor }}
                >
                  <Text fontSize="md" fontWeight="bold" color={accentColor} mb={2}>
                    {entry.question}
                  </Text>
                  <Text fontSize="sm" color={textColor} noOfLines={2}>
                    {entry.answer}
                  </Text>
                </Box>
              ))
            ) : (
              <Text color={textColor} fontSize="md" textAlign="center">
                ingen spørsmål stilt ennå.
              </Text>
            )}
          </VStack>

          <Text fontSize="xs" color={textColor} mt={4} textAlign="center" opacity={0.7} p={12}>
            ansvarsfraskrivelse: AI-svar er ikke alltid nøyaktige eller fullstendige. Bruk informasjonen med skjønn.
          </Text>
        </Flex>
      </Container>

      {/* Payment Modal */}
      <Modal isOpen={isSubOpen} onClose={onSubClose} isCentered>
        <ModalOverlay />
        <ModalContent bg={boxBgColor}>
          <ModalCloseButton color={textColor} />
          <ModalBody>
            <Text color={textColor} mb={4}>
              For å bruke algomigo, trenger du å betale et engangsbeløp på 150 kr.
            </Text>
            <Text color={textColor} fontWeight="bold" mb={4}>
              Fordeler med betalingen:
            </Text>
            <VStack align="start" spacing={2} mb={4}>
              <Text color={textColor}>• Ubegrenset tilgang til AI-assistenten</Text>
              <Text color={textColor}>• Prioritert kundeservice</Text>
            </VStack>
          </ModalBody>
          <ModalFooter display="flex" flexDirection="column" alignItems="center">
            <Input
              placeholder="skriv din e-postadresse her"
              mb={4}
              type="email"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              required
              color={textColor}
              borderColor={accentColor}
              _hover={{ borderColor: '#4FD1C5' }}
              _focus={{ borderColor: '#4FD1C5' }}
            />
                      
            <StripeWrapper email={inputEmail} handleEmailChange={handleEmailChange}/>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Log Entry Modal */}
      <Modal isOpen={isLogOpen} onClose={onLogClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent bg={boxBgColor} mx="auto" my="auto">
          <ModalCloseButton color={textColor} />
          <ModalBody p={6}>
            <Text fontWeight="bold" mb={2} color={textColor}>{selectedLogEntry?.question}</Text>
            <Text whiteSpace="pre-wrap" color={textColor}>{selectedLogEntry?.answer}</Text>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="center">
            <Button bg={accentColor} color="white" onClick={onLogClose} _hover={{ bg: '#4FD1C5' }}>
              lukk
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Success Modal */}
      <SuccessModal 
        isOpen={isSuccessModalOpen} 
        onClose={() => {
          setIsSuccessModalOpen(false);
          if (user?.email) {
            updateUser(user.email);
          }
        }} 
        sessionId={sessionId}
        onPaymentConfirmed={handlePaymentConfirmed}
      />  
    </Box>
  );
}

export default MainPage;