import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';



const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? 
  'http://localhost:3001/api' : 'https://algomigo-be.vercel.app/api';



interface User {
  email: string;
  credits: number;
  hasPaid: boolean;
}



interface AuthContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  updateUser: (email: string) => Promise<void>;
  updateUserCredits: (credits: number) => Promise<void>;
}



const AuthContext = createContext<AuthContextType | undefined>(undefined);



export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);



  const updateUser = useCallback(async (email: string) => {
    try {
      const response = await axios.post(`${API_URL}/admin/get-data`, { email });
      const userData = response.data;

      if (userData) {
        setUser({
          email: userData.email,
          credits: userData.credits,
          hasPaid: userData.hasPaid
        });
        localStorage.setItem('userEmail', userData.email);
      } else {
        console.log('No user data received');
      }
    } catch (error) {
      console.error('Error fetching user data.');
    }
  }, []);



  const updateUserCredits = useCallback(async (credits: number) => {
    if (user) {
      try {
        await axios.post(`${API_URL}/admin/update-credits`, { email: user.email, credits });
        setUser(prevUser => prevUser ? { ...prevUser, credits, hasPaid: true } : null);
      } catch (error) {
        console.error(`Error updating user credits for ${user.email}: ${error}`);
      }
    } else {
      console.log('No user found to update credits.');
    }
  }, [user]);



  useEffect(() => {
    const fetchUserOnFirstVisit = async () => {
      const storedEmail = localStorage.getItem('userEmail');
      if (storedEmail && !user) {
        await updateUser(storedEmail);
      }
    };

    fetchUserOnFirstVisit();
  }, [updateUser, user]);



  return (
    <AuthContext.Provider value={{ user, setUser, updateUser, updateUserCredits }}>
      {children}
    </AuthContext.Provider>
  );
};



export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};