import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';



import MainPage from './MainPage';
import NftCrafter from './nft/NftCrafter';
import { AuthProvider } from './AuthProvider';
import { VisitorProvider } from './VisitorProvider';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



const enableNftFunctionality = process.env.REACT_APP_ENABLE_NFT_FUNCTIONALITY === 'true';



root.render(
  <React.StrictMode>
    <ChakraProvider>
      <VisitorProvider>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/success" element={<MainPage />} />
              {enableNftFunctionality && <Route path="/nft" element={<NftCrafter />} />}
            </Routes>
          </Router>
        </AuthProvider>
      </VisitorProvider>
    </ChakraProvider>
  </React.StrictMode>
);